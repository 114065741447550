// --------------------------------------------------
// APP.JS
// --------------------------------------------------

//
// Initialize Foundation
// --------------------------------------------------

$(document).foundation();

//
// Custom JS
// --------------------------------------------------

//top navigation
$(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
 if (oldSize == 'small') {
 	if ($('nav').is(':hidden')) {
 		$('nav').show();
 	}
 }

 if (newSize == 'small') {
	$('.hamburger').show();
	$('.x').hide();
	$('nav').hide();
	//$('.social-small').remove();
 }
});

$('.hamburger').on('click', function() {
	$(this).fadeOut('fast', function() {

	});
	//$('nav').css('height', '100%');
	$('nav').slideDown('slow/400/fast', function() {

	});
			$('.social-small').slideDown('fast', function() {

		});
	$('.x').fadeIn('slow/400/fast', function() {

	});
});

$('.x').on('click', function() {
	$(this).fadeOut('fast', function() {

	});
	$('.social-small').fadeOut('fast', function() {

	});
	$('nav').slideUp('slow/400/fast', function() {
	});
	$('.hamburger').fadeIn('slow/400/fast', function() {

	});
});

new WOW().init();
